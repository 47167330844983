<template>
  <div class="page" id="tags">
    <div class="d-flex justify-content-end my-2">
      <button
        class="shadow-none px-4 py-3 btn btn-primary"
        @click="isCreateModalOpen = true"
      >
        {{ $t('tags.create-tag.title') }}
      </button>
    </div>

    <data-table
      ref="tagsTable"
      :fields="translatedTableFields"
      :data="tags"
      :pagination="pagination"
      :isLoading="isLoading"
      @pageChange="onPageChange"
    >
      <template slot="actions" slot-scope="props">
        <div class="table-button-container">
          <button
            class="mx-1 px-3 btn btn-sm btn-warning"
            @click="
              isUpdateModalOpen = true
              currentTag = props.rowData
            "
            v-if="!props.rowData.isDeleted"
          >
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>
          <button
            class="mx-1 px-3 btn btn-info btn-sm"
            @click="restoreTag(props.rowData.id)"
            v-if="props.rowData.isDeleted"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>
          <button
            class="mx-1 px-3 btn btn-danger btn-sm"
            @click="deleteTag(props.rowData.id)"
            v-else
          >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </template>
    </data-table>

    <Modal :isOpen="isCreateModalOpen" @dismiss="isCreateModalOpen = false">
      <CreateTagForm
        @tagCreated="
          isCreateModalOpen = false
          fetchTags()
        "
      />
    </Modal>
    <Modal :isOpen="isUpdateModalOpen" @dismiss="isUpdateModalOpen = false">
      <UpdateTagForm
        :tag="currentTag"
        @tagUpdated="
          isUpdateModalOpen = false
          fetchTags()
        "
      />
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Modal from '../../../elements/Modal.vue'
import DataTable from '../../../elements/DataTable'
import tagService from '../../../services/tags.service'
import CreateTagForm from './components/CreateTagForm.vue'
import UpdateTagForm from './components/UpdateTagForm.vue'

import isDeleted from './cells/isDeleted'
Vue.component('isDeleted', isDeleted)

export default {
  components: {
    DataTable,
    Modal,
    CreateTagForm,
    UpdateTagForm
  },
  name: 'agents',
  data() {
    return {
      value: [],
      options: [
        { name: 'Vue.js', language: 'JavaScript' },
        { name: 'Adonis', language: 'JavaScript' },
        { name: 'Rails', language: 'Ruby' },
        { name: 'Sinatra', language: 'Ruby' },
        { name: 'Laravel', language: 'PHP' },
        { name: 'Phoenix', language: 'Elixir' }
      ],

      tags: [],
      isLoading: false,
      isCreateModalOpen: false,
      isUpdateModalOpen: false,
      currentTag: null,
      tableFields: [
        {
          name: 'nameAR',
          title: 'tags.tags.table.nameAR'
        },
        {
          name: 'nameEN',
          title: 'tags.tags.table.nameEN'
        },
        {
          name: '__component:isDeleted',
          title: 'tags.tags.table.isDeleted'
        },
        {
          name: '__slot:actions',
          title: 'tags.tags.table.actions'
        }
      ],
      pagination: {
        perPage: 10
      }
    }
  },
  computed: {
    translatedTableFields() {
      return this.tableFields.map(field => {
        return {
          ...field,
          title: this.$t(field.title)
        }
      })
    }
  },
  methods: {
    async fetchTags() {
      this.isLoading = true
      try {
        const { callTags } = await tagService.getAllTags()
        this.tags = callTags
      } catch (error) {
        this.toast(error.message, { type: 'error' })
      } finally {
        this.isLoading = false
      }
    },
    onPageChange(page) {
      this.pagination.page = page
    },
    async restoreTag(tagId) {
      this.isLoading = true

      try {
        await tagService.restoreTag(tagId)
        await this.fetchTags()
      } catch (error) {
        this.toast(error.message, { type: 'error' })
      } finally {
        this.isLoading = false
      }
    },
    async deleteTag(tagId) {
      this.isLoading = true
      try {
        await tagService.deleteTag(tagId)
        await this.fetchTags()
      } catch (error) {
        this.toast(error.message, { type: 'error' })
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.fetchTags()
  }
}
</script>

<style lang="scss" scoped></style>
