<template>
  <span
    class="badge"
    :class="rowData.isDeleted ? 'badge-warning' : 'badge-primary'"
  >
    {{ rowData.isDeleted ? 'Deleted' : 'Active' }}
  </span>
</template>

<script>
export default {
  name: 'ExtensionStatus',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  }
}
</script>
