<template>
  <div class="form">
    <h2 class="mt-2 mb-3">{{ $t('tags.create-tag.title') }}</h2>
    <div class="w-100 row">
      <div class="mb-4 col-12">
        <Field
          :label="$t('tags.create-tag.nameAR')"
          v-model="tag.nameAR"
          :placeholder="$t('tags.create-tag.nameAR')"
          :error="errors.nameAR"
        />
      </div>
      <div class="mb-4 col-12">
        <Field
          :label="$t('tags.create-tag.nameEN')"
          v-model="tag.nameEN"
          :placeholder="$t('tags.create-tag.nameEN')"
          :error="errors.nameEN"
        />
      </div>

      <div class="col-12">
        <button @click="createTag" class="btn btn-primary">
          {{ $t('tags.create-tag.submit') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { object, string } from 'yup'
import Field from '../../../../elements/Field.vue'
import { yupToKV } from '../../../../utils/yup'
import tagService from '../../../../services/tags.service'

export default {
  components: {
    Field
  },
  data() {
    return {
      tag: {
        nameAR: '',
        nameEN: ''
      },
      errors: {},
      loading: false
    }
  },
  methods: {
    async createTag() {
      this.loading = true
      await this.createSchema
        .validate(this.tag, { abortEarly: false })
        .catch(err => {
          this.errors = yupToKV(err)
        })

      if (this.createSchema.isValidSync(this.tag)) {
        this.errors = {}
        try {
          await tagService.createTag(this.tag)
          this.toast(this.$t('tags.create-tag.toasts.tag-created'))
          this.$emit('tagCreated')
        } catch (error) {
          this.toast(error.response.data.message, { type: 'error' })
        } finally {
          this.loading = false
          this.tag = {
            nameAR: '',
            nameEN: ''
          }
        }
      } else {
        this.loading = false
      }
    }
  },
  computed: {
    createSchema() {
      return object().shape({
        nameAR: string()
          .trim()
          .required(this.$t('tags.create-tag.nameARRequired'))
          .min(2, this.$t('tags.create-tag.nameARMin')),
        nameEN: string()
          .trim()
          .required(this.$t('tags.create-tag.nameENRequired'))
          .min(2, this.$t('tags.create-tag.nameENMin'))
      })
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
}
.form-group {
  margin-bottom: 15px;
}
</style>
