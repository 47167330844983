<template>
  <div class="form">
    <h2 class="mt-2 mb-3">{{ $t('tags.update-tag.title') }}</h2>
    <div class="w-100 row">
      <div class="mb-4 col-12">
        <Field
          :label="$t('tags.update-tag.nameAR')"
          v-model="currentTag.nameAR"
          :placeholder="$t('tags.update-tag.nameAR')"
          :error="errors.nameAR"
        />
      </div>
      <div class="mb-4 col-12">
        <Field
          :label="$t('tags.update-tag.nameEN')"
          v-model="currentTag.nameEN"
          :placeholder="$t('tags.update-tag.nameEN')"
          :error="errors.nameEN"
        />
      </div>

      <div class="col-12">
        <button @click="updateTag" class="btn btn-primary">
          {{ $t('tags.update-tag.submit') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { object, string } from 'yup'
import Field from '../../../../elements/Field.vue'
import { yupToKV } from '../../../../utils/yup'
import tagService from '../../../../services/tags.service'

export default {
  components: {
    Field
  },
  props: {
    tag: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentTag: {},
      errors: {},
      loading: false
    }
  },
  methods: {
    async updateTag() {
      this.loading = true
      await this.updateSchema
        .validate(this.currentTag, { abortEarly: false })
        .catch(err => {
          this.errors = yupToKV(err)
        })

      if (this.updateSchema.isValidSync(this.currentTag)) {
        this.errors = {}
        try {
          await tagService.updateTag(this.currentTag.id, this.currentTag)
          this.toast(this.$t('tags.update-tag.toasts.tag-updated'))
          this.$emit('tagUpdated')
        } catch (error) {
          this.toast(error.response.data.message, { type: 'error' })
        } finally {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    }
  },
  computed: {
    updateSchema() {
      return object().shape({
        nameAR: string()
          .trim()
          .required(this.$t('tags.update-tag.nameARRequired'))
          .min(2, this.$t('tags.update-tag.nameARMin')),
        nameEN: string()
          .trim()
          .required(this.$t('tags.update-tag.nameENRequired'))
          .min(2, this.$t('tags.update-tag.nameENMin'))
      })
    }
  },
  watch: {
    tag: {
      handler() {
        this.currentTag = { ...this.tag }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
}
.form-group {
  margin-bottom: 15px;
}
</style>
